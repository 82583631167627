#PageLogin {
	background-color: transparent;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100vh;

	.ant-layout-content {
		background-color: transparent;
		width: 600px;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		gap: 50px;

		@include atsm {
			width: 90%;
		}

		@include atxs {
			width: 95%;
		}

		.logo-wrapper {
			// width: 380px;
			padding-top: 50px;
			text-align: center;

			img {
				width: 150px;
			}
		}

		.ant-card {
			width: 100%;

			box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
			-webkit-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
			-moz-box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.75);
		}

		.ant-layout-footer {
			width: 100%;
			text-align: center;
			background-color: transparent;

			.ant-typography {
				color: $footerTextColor;
			}
		}
	}
}
