.private-layout {
	> .ant-layout-header {
		background-color: $headerBackground !important;
		height: 70px;
		padding: 0px 15px 0px 15px;
		display: flex;
		z-index: 1;
		gap: 8px;

		// box-shadow: -1px 3px 3px 0px rgba(0, 0, 0, 0.4);
		// -webkit-box-shadow: -1px 3px 3px 0px rgba(0, 0, 0, 0.4);
		// -moz-box-shadow: -1px 3px 3px 0px rgba(0, 0, 0, 0.4);

		.header-left-menu {
			width: 100%;
			display: flex;
			align-items: center;

			> .ant-page-header {
				background-color: $pageHeaderBackground;
				padding: 0px;

				.ant-page-header-heading {
					width: 100%;
					display: inherit;

					.ant-page-header-heading-left {
						width: 100%;
						display: inherit;
						margin: 0px;

						.ant-page-header-heading-title {
							display: flex !important;
							gap: 10px;
							align-items: center;
							margin: 0px;
							color: $headerTextColor;

							.ant-page-header-icon {
								border: 3px solid $pageHeaderIconBorderColor;
								border-radius: 100%;
								display: flex;
								justify-content: center;
								align-items: center;
								padding: 12px;

								// @include atxs {
								// 	width: 55px !important;
								// 	height: 55px !important;
								// 	padding: 10px;
								// }

								svg {
									font-size: 24px;

									path {
										color: $pageHeaderIconColor;
									}
								}
							}

							.ant-page-header-text {
								display: flex;
								flex-direction: column;
								align-items: flex-start;

								.sub-title {
									color: $pageHeaderColorSubTitle;
									text-transform: none;
									font-size: 16px;
									font-weight: 600;
									line-height: normal;
									font-family: MontserratBold !important;

									@include atxs {
										font-size: 12px;
									}
								}
								.title {
									color: $pageHeaderColorTitle;
									font-size: 28px;
									font-weight: 700;
									line-height: normal;
									font-family: MontserratBold !important;

									@include atxs {
										font-size: 25px;
									}
								}
							}
						}
					}
				}
			}
		}

		.header-right-menu {
			display: flex;
			align-items: center;
			gap: 20px;

			@include atxs {
				gap: 15px;
			}

			.ant-image.menu-submenu-profile {
				transition: transform 0.2s;

				&:hover {
					transform: scale(1.1);
				}

				img {
					object-fit: cover;
					-o-object-fit: cover;
					width: 60px;
					height: 60px;
					border-radius: 50%;
					border: 2px solid $headerDropdownBorderColor;
					cursor: pointer;
					overflow: hidden;
					background: #fff;

					@include atmd {
						width: 55px;
						height: 55px;
					}
				}
			}

			.menu-submenu-notification {
				cursor: pointer;
				color: #abf0ef;
				width: 35px;
				height: 35px;
				justify-content: center;
				align-items: center;

				&:hover {
					transform: scale(1.1);
					svg {
						color: $headerIconColorActive;
					}
				}

				svg {
					color: $headerRightIconColor;
					font-size: 42px;

					@include atmd {
						font-size: 32px;
					}

					@include atsm {
						font-size: 28px;
					}
				}

				.ant-badge-count {
					right: 0px;
					top: 10px;
					width: 18px;
					height: 18px;
					font-size: 10px;
					border-radius: 50%;
					padding: 0px;
					display: flex;
					align-items: center;
					justify-content: center;

					@include atsm {
						right: 0px;
						min-width: 15px;
						width: 15px;
						height: 15px;
						font-size: 8px;
						line-height: 15px;
					}

					.ant-scroll-number-only {
						@include atsm {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}

			.menu-submenu-message-alert {
				cursor: pointer;
				margin-top: 2px;

				&:hover {
					svg {
						color: $headerIconColorActive;
					}
				}

				svg {
					color: $headerRightIconColor;
					font-size: 45px;

					@include atmd {
						font-size: 32px;
					}

					@include atsm {
						font-size: 30px;
					}
				}

				.ant-badge-count {
					right: -2px;
					top: 10px;
					width: 18px;
					height: 18px;
					font-size: 10px;
					border-radius: 50%;
					padding: 0px;
					display: flex;
					align-items: center;
					justify-content: center;

					@include atmd {
						top: 12px;
					}

					@include atsm {
						right: 0px;
						min-width: 15px;
						width: 15px;
						height: 15px;
						font-size: 8px;
						line-height: 15px;
					}

					.ant-scroll-number-only {
						@include atsm {
							display: flex;
							align-items: center;
							justify-content: center;
						}
					}
				}
			}

			.icon-menu-logout {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 8px;

				svg {
					color: $headerRightIconColor;
					font-size: 20px;
				}
			}

			.icon-menu-back {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 8px;

				svg {
					color: $headerRightIconColor;
					font-size: 20px;

					@include atmd {
						font-size: 32px;
					}

					@include atsm {
						font-size: 28px;
					}
				}
			}

			.icon-menu-network {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 8px;

				svg {
					color: $headerRightIconColor;
					font-size: 20px;
				}

				&.online {
					svg {
						color: $success;
					}
				}
				&.offline {
					svg {
						color: $danger;
					}
				}
			}
			.icon-menu-sync {
				cursor: pointer;
				display: flex;
				align-items: center;
				gap: 8px;

				svg {
					color: $success;
					font-size: 20px;
				}
			}
		}
	}
}

.menu-submenu-profile-popup {
	width: 300px;
	top: 70px !important;

	.ant-dropdown-menu {
		padding: 0px 15px 15px 15px;
		border-radius: 0px;
		box-shadow: none;

		.ant-dropdown-menu-item {
			&.menu-item-profile-details {
				background-color: transparent;

				&:hover {
					background-color: transparent;
				}

				.menu-item-details-wrapper {
					display: flex;
					align-items: center;
					gap: 8px;
					height: 100px;
					padding-bottom: 10px;
					margin-bottom: 10px;
					border-bottom: 1px solid $headerDropdownBorderColor;

					.ant-image {
						transition: transform 0.2s;

						img {
							object-fit: cover;
							-o-object-fit: cover;
							width: 60px;
							height: 60px;
							border-radius: 50%;
							border: 2px solid $headerDropdownBorderColor;
							cursor: pointer;
							overflow: hidden;
							background: #fff;
						}
					}

					.info-wrapper {
						.ant-typography {
							white-space: break-spaces;
						}

						.info-username {
							color: $headerDropdownTextColor;
							font-weight: 600;
							font-size: 17px;
						}
					}
				}
			}

			svg {
				color: $headerDropdownIconColor;
				stroke: $headerDropdownIconColor;
				path {
					color: $headerDropdownIconColor;
					stroke: $headerDropdownIconColor;
				}
			}

			&.ant-dropdown-menu-item-active {
				a {
					color: $headerDropdownTextColorActive;
				}
				svg {
					color: $headerDropdownIconColorActive;
					stroke: $headerDropdownIconColorActive;
					path {
						color: $headerDropdownIconColorActive;
						stroke: $headerDropdownIconColorActive;
					}
				}
			}

			.ant-dropdown-menu-title-content {
				a {
					text-decoration: none;
				}
			}
		}
	}
}

.menu-submenu-notification-popup {
	width: 300px;
	top: 100px !important;

	@include atsm {
		top: 70px !important;
	}

	@include atxs {
		top: 70px !important;
	}

	.ant-dropdown-menu {
		padding: 0px 15px 15px 15px;
		border-radius: 0px;

		.ant-dropdown-menu-item {
			&:first-child {
				color: #2b7273;
				font-size: 16px;
				font-weight: 600;
			}
			&:last-child {
				color: rgb(0, 60, 128);
			}

			.ant-dropdown-menu-title-content {
				a {
					text-decoration: none;
				}
			}
		}

		.ant-dropdown-menu-item-divider {
			height: 1px;
			margin: 4px 0;
			overflow: hidden;
			line-height: 0;
			background-color: rgb(54 50 50 / 19%);
		}
	}
}
