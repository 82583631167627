#PageDashboard {
	overflow: hidden;

	> .ant-layout-content {
		> .ant-card {
			box-shadow: none;

			> .ant-card-body {
				padding: 0px;
			}
		}
	}

	.ant-carousel {
		.slick-slider {
			&.intro {
				.slick-arrow {
					background-color: rgba(0, 0, 0, 0.4);
					padding: 15px;
					border-radius: 50%;

					&::after {
						top: 10px;
						left: 11px;
					}

					&.slick-next {
						&::after {
							left: 7px;
						}
					}
				}

				.slick-list {
					.slick-track {
						.slick-slide {
							> div {
								.carousel-item-home {
									display: flex !important;
									flex-direction: column;
									text-align: center;
									justify-content: center;

									color: #ffffff;

									.title {
										font-weight: 500;
										font-size: 18px;
									}

									.sub-title {
										font-size: 40px;
										line-height: 40px;
									}
								}
							}
						}
					}
				}
			}
		}
	}

	.ant-divider {
		border-block-start: 2px solid rgba(5, 5, 5, 0.06);
	}

	.survey-list-wrapper {
		background-color: transparent;
		// background-color: $mainPrimary;
		box-shadow: none;

		.ant-card-head {
			padding-left: 30px;
			padding-right: 30px;
			border-radius: 0px;
			border-bottom: 0px;

			.ant-card-head-wrapper {
				.ant-card-head-title {
					display: flex;
					gap: 12px;

					.title {
						color: #858585;
						font-weight: 700;
						font-family: MontserratBold !important;
					}

					.action {
						.ant-badge {
							sup.ant-scroll-number.ant-badge-count {
								line-height: 12px;
								min-width: 12px;
								height: 12px;
								font-size: 8px;

								border-radius: 50%;
								background: rgba(255, 82, 82, 1);
								box-shadow: 0 0 0 0 rgba(255, 82, 82, 1);
								animation: pulse-red 2s infinite;
								right: -6px;
								top: -3px;

								.ant-scroll-number-only {
									height: 12px;
									font-size: 8px;
									.ant-scroll-number-only-unit {
										font-size: 8px;
									}
								}
							}
						}
					}
				}
			}
		}

		.ant-card-body {
			padding: 0px 15px;

			.div-no-survey-list {
				background-color: $mainPrimary;
				border-radius: 30px;
				padding: 15px;

				box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);

				.content {
					// color: #162347;
					color: #ffffff;
					font-size: 16px;
					text-align: center;
				}
			}

			.ant-list {
				border: 0px;
				border-radius: 0px;

				.ant-list-items {
					.ant-list-item {
						padding: 0px;
						border: 0px;

						.wrapper-content {
							display: flex;
							justify-content: space-between;
							width: 100%;
							background-color: $mainPrimary;
							border-radius: 35px;
							padding: 15px;
							align-items: center;
							box-shadow: 0px 0px 10px 1px rgba(0, 0, 0, 0.4);

							.content {
								.ant-typography {
									color: #ffffff;
									font-size: 18px;
									font-weight: 600;
								}
							}

							.action {
								.ant-btn {
									border-radius: 20px !important;
									span {
										font-family: MontserratBold !important;
									}
								}
							}
						}
					}
				}
			}
		}
	}
}

@keyframes pulse-red {
	0% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0.7);
	}

	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(255, 82, 82, 0);
	}

	100% {
		transform: scale(0.95);
		box-shadow: 0 0 0 0 rgba(255, 82, 82, 0);
	}
}
