.ant-collapse {
	border-radius: 0px;
	border: 0px;

	&.ant-collapse-large {
		.ant-collapse-item {
			.ant-collapse-header {
				padding: 10px 24px;
			}
		}
	}

	.ant-collapse-item {
		border: 1px solid #d9d9d9;

		&:not(:first-child) {
			margin-top: 20px;
		}

		.ant-collapse-header {
			.ant-collapse-expand-icon {
				margin-inline-start: 0px;
				svg {
					font-size: 18px;
				}
			}
		}

		&:last-child {
			border-radius: 0px;

			> .ant-collapse-header {
				border-radius: 0px;
			}
		}

		.ant-collapse-content {
			.ant-collapse-content-box {
				padding: 15px;
			}
		}
	}

	&.collapse-main-primary {
		.ant-collapse-item {
			.ant-collapse-header {
				background-color: $collapseHeader;
				color: #ffffff;
			}
		}
	}
}
