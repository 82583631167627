#PageAccountProfile {
	> .ant-layout-content {
		.ant-card.card-account-profile-info {
			margin: 15px;

			> .ant-card-body {
				padding: 20px;

				.ant-image {
					height: 200px;

					.ant-image-img {
						height: 200px;
					}
				}
			}
		}
	}
}
