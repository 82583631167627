.primary-color {
    color: $primary;
}
.primary-bg-color {
    background-color: $primary;
}
.primary-color-active {
    color: $primaryActive;
}
.primary-bg-color-active {
    background-color: $primaryActive;
}
.second-color {
    color: $secondary;
}
.second-bg-color {
    background-color: $secondary;
}
.second-color-active {
    color: $secondaryActive;
}
.second-bg-color-active {
    background-color: $secondaryActive;
}

.success-color {
    color: $success;
}
.success-bg-color {
    background-color: $success;
}
.success-color-active {
    color: $successActive;
}
.success-bg-color-active {
    background-color: $successActive;
}
.warning-color {
    color: $warning;
}
.warning-bg-color {
    background-color: $warning;
}
.warning-color-active {
    color: $warningActive;
}
.warning-bg-color-active {
    background-color: $warningActive;
}
.danger-color {
    color: $danger;
}
.danger-bg-color {
    background-color: $danger;
}
.danger-color-active {
    color: $dangerActive;
}
.danger-bg-color-active {
    background-color: $dangerActive;
}
.info-color {
    color: $info;
}
.info-bg-color {
    background-color: $info;
}
.info-color-active {
    color: $infoActive;
}
.info-bg-color-active {
    background-color: $infoActive;
}
.indigo-color {
    color: $indigo;
}
.indigo-bg-color {
    background-color: $indigo;
}
.indigo-color-active {
    color: $indigoActive;
}
.indigo-bg-color-active {
    background-color: $indigoActive;
}
.purple-color {
    color: $purple;
}
.purple-bg-color {
    background-color: $purple;
}
.purple-color-active {
    color: $purpleActive;
}
.purple-bg-color-active {
    background-color: $purpleActive;
}
.pink-color {
    color: $pink;
}
.pink-bg-color {
    background-color: $pink;
}
.pink-color-active {
    color: $pinkActive;
}
.pink-bg-color-active {
    background-color: $pinkActive;
}
.teal-color {
    color: $teal;
}
.teal-bg-color {
    background-color: $teal;
}
.teal-color-active {
    color: $tealActive;
}
.teal-bg-color-active {
    background-color: $tealActive;
}
.dark-color {
    color: $dark;
}
.dark-bg-color {
    background-color: $dark;
}
.dark-color-active {
    color: $darkActive;
}
.dark-bg-color-active {
    background-color: $darkActive;
}
.orange-color {
    color: $orange;
}
.orange-bg-color {
    background-color: $orange;
}
.orange-color-active {
    color: $orangeActive;
}
.orange-bg-color-active {
    background-color: $orangeActive;
}

.main-primary-color {
    color: $mainPrimary;
}
.main-primary-bg-color {
    background-color: $mainPrimary;
}
.main-second-color {
    color: $mainSecondary;
}
.main-second-bg-color {
    background-color: $mainSecondary;
}
