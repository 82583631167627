.tbl-top-filter,
.tbl-bottom-filter {
	display: flex;
	justify-content: space-between;
}
.tbl-top-filter {
	@include atxs {
		flex-direction: column;
		gap: 12px;
	}

	.tbl-page-size {
		display: flex;
		align-items: center;
		gap: 5px;

		@include atsm {
			width: 200px;
		}

		.ant-select {
			.ant-select-selector {
				border-radius: 0px;
			}
		}
		.ant-typography {
			font-size: 16px;
		}
	}
	.tbl-search-wrapper {
		width: 300px;

		@include atsm {
			width: 100%;
		}

		.ant-input-group-wrapper {
			// &.ant-input-search {}

			// &.ant-input-search-large{}

			.ant-input {
				border-radius: 0px;

				&.ant-input-lg {
					height: 45px;
				}
			}

			.ant-input-group-addon {
				.ant-btn {
					&.ant-btn-lg {
						&.ant-btn-icon-only {
							&.ant-input-search-button {
								display: flex;
								align-items: center;
								justify-content: center;
							}
						}
					}
				}
			}
		}
	}
}

.tbl-bottom-filter {
	display: flex;
	align-items: center;

	@include atsm {
		flex-direction: column;
		gap: 12px;
	}

	.ant-typography {
		font-size: 16px;
	}

	.ant-pagination {
		@include atsm {
			width: 100%;
			display: flex;
			justify-content: center;
		}

		.ant-pagination-item {
			border-radius: 0px;
			border-color: #d9d9d9;

			&.ant-pagination-item-active {
				background-color: $mainPrimary;
				border-color: $mainPrimary;

				a {
					color: $mainPrimaryText;
				}
			}

			a {
				color: rgba(0, 0, 0, 0.85);
			}
		}
	}
}

.ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:first-child {
	border-radius: 0px;
}
.ant-table-wrapper
	.ant-table-container
	table
	> thead
	> tr:first-child
	> *:last-child {
	border-radius: 0px;
}
