// Body
$body-bg: #1890ff;
$back-image: url("../../../../assets/img/login_bg.png");

// Typography
$font-family-sans-serif: "Nunito", sans-serif;
$font-size-base: 0.9rem;
$line-height-base: 1.6;

@font-face {
	font-family: "MontserratVariableFont_wght";
	src: url("../fonts/Montserrat/Montserrat-VariableFont_wght.ttf");
}

@font-face {
	font-family: "MontserratRegular";
	src: local("Montserrat"),
		url("../fonts/Montserrat/static/Montserrat-Regular.ttf") format("truetype");
}

@font-face {
	font-family: "MontserratBold";
	src: local("MontserratBold"),
		url("../fonts/Montserrat/static/Montserrat-Bold.ttf") format("truetype");
}

@font-face {
	font-family: "MontserratMedium";
	src: local("MontserratMedium"),
		url("../fonts/Montserrat/static/Montserrat-Medium.ttf") format("truetype");
}

@font-face {
	font-family: "AnnabelleJFRegular";
	src: local("AnnabelleJFRegular"),
		url("../fonts/AnnabelleJFRegular/AnnabelleJFRegular.ttf") format("truetype");
}

$primary: #0866c6;
$primaryActive: #0753a1;

$secondary: #868e96;
$secondaryActive: #727b84;

$success: #23bf08;
$successActive: #1c9a06;

$warning: #f49917;
$warningActive: #da850a;

$danger: #dc3545;
$dangerActive: #c82333;

$info: #5b93d3;
$infoActive: #3d7fcb;

$indigo: #6610f2;
$indigoActive: #560bd0;

$purple: #6f42c1;
$purpleActive: #5e37a6;

$pink: #e83e8c;
$pinkActive: #e41c78;

$teal: #1caf9a;
$tealActive: #178e7d;

$dark: #343a40;
$darkActive: #23272b;

$orange: #f27510;
$orangeActive: #d16a17;

// layout
$layoutBackground: #f0f8ff;
$textColor: #ffffff;
$iconColor: #009999;
$textColorActive: #ffffff;
$iconColorActive: #ffffff;

// layout header
$headerBackground: #ffffff;
$headerBackgroundSelectedMenu: #2d60e2;
$headerTextColor: #2d60e2;
$headerLeftIconColor: #2d60e2;
$headerRightIconColor: #2d60e2;
$headerTextColorActive: #2d60e2;
$headerIconColorActive: #2d60e2;
$headerProfileColor: #2d60e2;

// layout header dropdown
$headerDropdownBackgroundSelectedMenu: #358e8f;
$headerDropdownTextColor: #58585a;
$headerDropdownIconColor: #0157de;
$headerDropdownTextColorActive: #0157de;
$headerDropdownIconColorActive: #58585a;
$headerDropdownTitleColor: #0157de;
$headerDropdownImgBorderColor: #13f4f1;
$headerDropdownBorderColor: #949599;

// breadcrumColor
$breadcrumColor: transparent;
$breadcrumTextColor: #163ca6;
$breadcrumIconColor: #163ca6;
$breadcrumLastTextColor: #e4151f;

// page header
$pageHeaderBackground: transparent;
$pageHeaderBorderColor: transparent;
$pageHeaderColorTitle: #162347;
$pageHeaderColorSubTitle: #9ea4b4;
$pageHeaderIconColor: #2d60e2;
$pageHeaderIconBorderColor: #2d60e2;

$mainPrimary: #2d60e2;
$mainPrimaryText: #ffffff;
$mainPrimaryActive: #ffffff;
$mainPrimaryTextActive: #2d60e2;
$mainSecondary: #ff8303;
$mainSecondaryText: #ffffff;
$mainSecondaryActive: #b0b4b4;
$mainSecondaryTextActive: #ff8303;
$mainTertiary: #e4151f;
$mainTertiaryText: #ffffff;
$mainTertiaryActive: #b0b4b4;
$mainTertiaryTextActive: #e4151f;

$tooltip-success: #d9f1d1;
$tooltip-primary: #e0e9f5;
$tooltip-warning: #faecd4;
$tooltip-danger: #f3d8da;
$tooltip-success-text: #2e611a;
$tooltip-primary-text: #354b6b;
$tooltip-warning-text: #78521e;
$tooltip-danger-text: #692326;

// Floating
$floatLabel: #918f8ffa;

// Collapse
$collapseHeader: #3d445d;

$table-scrollbar-thumb-color: #1e84c1;
$table-scrollbar-thumb-width: 8px;
$table-scrollbar-track-color: #bbb;

$footerTextColor: #000000;
