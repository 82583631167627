#PageSurveyForm {
	.ant-layout-header {
		display: none;
	}

	.ant-layout-content {
		padding: 0px;
		padding-bottom: 100px;

		> .ant-form {
			> .ant-row {
				> .ant-col {
					margin-left: 0px !important;
					margin-right: 0px !important;
				}
			}
		}
	}

	.form-name-wrapper {
		position: relative;
		padding-left: 0px !important;
		padding-right: 0px !important;

		.btn-back {
			position: absolute;
			top: 20px;
			left: 20px;

			@include atxs {
				left: 15px;
			}

			.ant-btn-icon {
				font-size: 20px;
			}
		}
	}
	.form-name {
		margin: 15px 0px;
		background-color: #ffffff;
		padding: 15px 20px;
		border-radius: 30px;
		text-align: center;

		.ant-typography {
			margin: 0px;
		}
	}

	.ant-card {
		margin-bottom: 15px;
		border-radius: 5px;
		position: relative;

		> .ant-card-head {
			position: sticky;
			top: 0px;
			min-height: 100px;
			padding: 15px 15px 10px 15px;
			background-color: #fff;
			z-index: 1;
			border-bottom: 1px solid #ccc;
			justify-content: flex-end;

			@include atxs {
				padding: 15px 15px 10px 15px;
				min-height: 50px;
			}

			.ant-card-head-wrapper {
				.ant-card-head-title {
					font-size: 20px;
					text-align: center;

					.title-wrapper {
						display: flex;
						justify-content: center;

						.title {
							white-space: break-spaces;
						}
					}

					.ant-steps {
						&.ant-steps-vertical {
							display: none;
						}

						.ant-steps-item {
							padding-inline-end: 4px;

							.ant-steps-item-container {
								.ant-steps-item-icon {
									margin-inline-start: 0px;
									margin-inline-end: 0px;
								}
								.ant-steps-item-content {
									.ant-steps-item-title {
										&::after {
											height: 2px;
										}

										.title {
											display: none;
										}
									}
								}
							}

							&.ant-steps-item-finish {
								.ant-steps-item-container {
									.ant-steps-item-icon {
										border-color: $success;

										.ant-steps-icon {
											color: $success;
										}
									}

									.ant-steps-item-content {
										.ant-steps-item-title {
											&::after {
												background-color: $success;
											}
										}
									}
								}
							}
						}
					}
				}
			}
		}
		> .ant-card-body {
			padding: 15px 20px;

			@include atxs {
				padding: 15px;
				display: flex;
				gap: 20px;

				&::before {
					display: none;
				}
			}

			.steps-mobile-wrapper {
				display: none;

				@include atxs {
					display: flex;
				}

				.ant-steps-item {
					padding-inline-end: 4px;
					flex: 0;

					.ant-steps-item-container {
						.ant-steps-item-icon {
							margin-inline-start: 0px;
							margin-inline-end: 0px;
						}
						.ant-steps-item-content {
							.ant-steps-item-title {
								&::after {
									height: 2px;
								}

								.title {
									display: none;
								}
							}
						}
					}

					&.ant-steps-item-finish {
						.ant-steps-item-container {
							.ant-steps-item-icon {
								border-color: $success;

								.ant-steps-icon {
									color: $success;
								}
							}

							.ant-steps-item-content {
								.ant-steps-item-title {
									&::after {
										background-color: $success;
									}
								}
							}
						}
					}
				}
			}

			.quill-output.question {
				font-weight: 700;
				margin-bottom: 5px;
			}

			.ant-form-item {
				.ant-input {
					border-radius: 20px;
				}

				.ant-select {
					.ant-select-selector {
						border-radius: 20px;
					}
				}

				.ant-picker {
					width: 100%;
					border-radius: 20px;
				}
			}
		}
	}

	.ant-btn.btn-main-primary {
		border-radius: 20px;
	}

	.ant-layout-footer {
		display: none;
	}
}

.modal-preview-answer {
	.ant-modal {
		width: 90% !important;
		padding-bottom: 20px !important;

		.ant-modal-content {
			.ant-modal-close {
				top: 15px !important;
			}
			.ant-modal-header {
				padding: 15px !important;
			}

			.ant-modal-body {
				padding: 15px 15px 0px 15px !important;

				.ant-card {
					&:not(:last-child) {
						margin-bottom: 15px;
					}

					.ant-card-head {
						min-height: auto;
						padding: 10px 15px;
					}
					.ant-card-body {
						padding: 10px 15px;

						.question-wrapper {
							margin-bottom: 10px;

							&.border {
								border-bottom: 1px solid #e8e8e8;
							}

							.options {
								margin-left: 15px;
							}
						}
					}
				}
			}
			.ant-modal-footer {
				margin-top: 0px;
				padding: 15px 15px !important;

				.btn-main-primary {
					&:not(.outlined) {
						color: $mainPrimaryText !important;
						background-color: $mainPrimary !important;
						border-color: $mainPrimary !important;
					}
				}
			}
		}
	}
}
